@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

$MAINCOLOR: #262161;
$SECONDCOLOR: #0E142C;
$THIRDCOLOR: #BF1E2E;
// $FOURTHCOLOR: #478ecc;

html{
  font-size: 16px;
  font-family: 'Raleway', sans-serif;
  scroll-behavior: smooth;
  font-size: 16px;
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
  // overflow: hidden;
}

body{
  font-family: 'Raleway', sans-serif;
}

.Toastify__toast-body {
  font-family: 'Raleway', sans-serif;
  text-align: right;
  font-weight: bold;
}

input:focus,
select:focus,
textarea:focus {
  outline: 0 !important;
}

.clear {
  clear: both;
}

img {
  max-width: 100%;
}

a,
button {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  text-decoration: none;
}

a{
  text-decoration: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.card_img{
  height: 400px;
  width: 100%;
  position: relative;
  .img_parent{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img, video{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.breadcrumb{
  padding: 100px 0;
  background-position: center;
  background-size: cover;
  position: relative;
  border-radius: 0;
  margin-bottom: 0;
  &::after{
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    content: "";
    width: 100%;
    height: 100%;
  }
  h5{
    margin: 0;
    font-size: 2.5rem;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    z-index: 3;
  }
}
 .removeLoading {
  visibility: hidden;
  opacity: 0;
  transition: all .5s ease-in-out;
 }

.openSideMenue{
  position: fixed;
  background-color: rgba(0,0,0,.3);
  width: 100%;
  min-height: 100vh;
  z-index: 9;
  left: -100%;
  // display: none;
}

.sideNav{
  background-color: #fff;
  width: 300px;
  height: 100vh;
  max-width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  box-shadow: 0 10px 10px rgba(0,0,0,.3);
  transition: all .3s ease-in-out;
  left: -100%;
  
  .cancel{
    padding: 15px 0;
    position: absolute;
    top: 0;
    right: 15px;
    img{
      width: 30px;
    }
  }
  .logo{
    text-align: center;
    padding: 30px 0 0 0;
    img{
      width: 150px;
    }
  }
  .list{
    li{
      a{
        padding: 15px;
        border-bottom: 1px solid #eee;
        color: $SECONDCOLOR;
        font-weight: bold;
        display: inline-block;
        width: 100%;
      }
    }
  }
}

.loading_parent{
  position: fixed;
  background-color: #fff;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
div.loading {
	width: 5rem;
	height: 5rem;
	background-color:$THIRDCOLOR;
	border-radius: 5rem;
	position: relative;
	transform: rotate(0deg);
	animation: spinning 1s linear infinite;

	&::before {
		content: "";
		height: 100%;
		width: 100%;
		position: absolute;
		background-color: white;
		border-radius: 100%;
		transform: translate(0.3rem);
	}
}

@keyframes spinning {
	0% {
		transform: rotate(0deg);
	}
	0% {
		transform: rotate(-360deg);
	}
}


.inner_page_compnent{
  padding: 100px 0;
}

.contact_us_innerPage{
  margin-top: 0 !important;
}


/* top_navbar */
.top_navbar{
  .wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo{
      a{
        display: block;
        width: 100px;
      }
    }
    .social{
      ul{
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        li{
          margin-left: 10px;
          a{
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: $THIRDCOLOR;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
          }
        }
      }
    }
  }
}


/* custom_navbar */
.custom_navbar{
  background-color: $SECONDCOLOR;
  .side_icon{
    display: none;
  }
  ul.list{
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
  .nav_link{
    color: #fff;
    font-size: .9rem;
    padding: 20px 15px;
    display: inline-block;
    text-decoration: none;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    &:hover{
      background-color: #fff;
      color: $SECONDCOLOR;
    }
  }
  .wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .join_us{
    .join_us_link{
      background-color: $THIRDCOLOR;
      display: inline-block;
      padding: 10px 15px;
      border-radius: 10px;
      color: #fff;
      border-color: $THIRDCOLOR;
      font-weight: 500;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-size: .9rem;
      &:hover{
        background-color: #fff;
        color: $THIRDCOLOR;
      }
    }
  }
}

.section_header{
  position: relative;
  padding-left: 15px;
  &::before{
    content: "";
    position: absolute;
    width: 4px;
    height: 100%;
    background-color: $THIRDCOLOR;
    left: 0;
    top: 0;
    border-radius: 2px;
  }
  h6{
    color: $MAINCOLOR;
    font-size: 1.2rem;
    margin: 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 600;
  }
  h5{
    font-size: 2.5rem;
    color: $SECONDCOLOR;
    margin: 0;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}


/* HEADER */
.header{
  .card_img{
    height: calc(100vh - 165px);
  }
}

/* about_us_section */
.about_us_section{
  padding: 50px 0;
  .about_img{
    text-align: center;
    img{
      width: 70%;
    }
  }
  .about_content{
    margin-top: 40px;
    h5{
      font-size: 2rem;
      letter-spacing: 3px;
    }
    .readmore{
      background-color: $THIRDCOLOR;
      color: #fff;
      padding: 10px 30px;
      // border-radius: 3px;
      margin-top: 5px;
      display: block;
      width: fit-content;
      margin-left: auto;
      border: 1px solid $THIRDCOLOR;
      &:hover{
        background-color: #fff;
        color: $THIRDCOLOR;
      }
    }
  }
}

/* services */
.services{
  padding: 50px 0;
  .section_header{
    margin-bottom: 50px;
  }
}

/* services_card */
.services_card{
  margin-bottom: 40px;
  border: 1px solid #eee;
  padding: 20px 15px;
  border-radius: 5px;
  min-height: 310px;
  .service_img{
    text-align: center;
    margin-bottom: 10px;
    img{
      width: 30%;
      margin: auto;
    }
  }
  .card_body{
    text-align: center;
    .card_title{
      font-size: 1.5rem;

    }
    .card_text{
      color: #000;
      // font-weight: 500;
      line-height: 23px;
    }
  }
}


/* organization_card */
.our_company{
  .section_header{
    margin-bottom: 50px;
  }
}
.organization_card{
  text-align: center;
  margin-bottom: 30px;
  .card_logo{
    margin-bottom: 10px;
    img{
      width: 120px;
    }
  }
  .card_title{
    font-size: 1.5rem;
    color: $THIRDCOLOR;
  }
  .card_text{
    color: #000;
    // font-weight: 500;
    line-height: 23px;
  }
  .organ_link{
    background-color: $SECONDCOLOR;
    color: #fff;
    padding: 7px 30px;
    margin-top: 10px;
    border: 1px solid $SECONDCOLOR;
    display: inline-block;
    border-radius: 5px;
    i{
      font-size: .9rem;
      margin-left: 5px;
    }
    &:hover{
      background-color: #fff;
      color: $SECONDCOLOR;
    }
  }
}


/* about_us */
.about_us{
  padding: 30px 0;
  .about_img_parnent{
    height: 300px;
    position: relative;
    .img_wrapper{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      img{
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  .about_content{
    p{
      font-size: 1.3rem;
    }
    h5{
      font-size: 1.3rem;
      font-weight: bold;
    }
    ul{
      margin-top: 30px;
      .row{
        align-items: center;
      }
      li{
        border: 1px solid #eee;
        margin-bottom: 20px;
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: all .3s ease-in-out;
        img{
          width: 50px;
          margin-bottom: 10px;
          display: inline-block;
        }
        span{
          font-size: 1.2rem;
        }
        &:hover{
          background-color: $SECONDCOLOR;
          img{
            // filter: invert(0) brightness(1);
          }
          span{
            color: #fff;
          }
        }
      }
    }
  }
}

/* contact_us */
.contact_us{
  padding: 50px 0;
  margin: 100px 0;
  margin-bottom: 0;
  background-size: cover;
  background-position: center;
  position: relative;
  &::after{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: rgba(0,0,0,.6);
  }
  .contact_header{
    text-align: center;
    position: relative;
    z-index: 3;
    h5{
      font-size: 3.5rem;
      text-transform: uppercase;
      font-weight: bold;
      color: #fff;
      margin: 0;
    }
    p{
      letter-spacing: 3px;
      color: #fff;
      text-transform: capitalize;
      margin: 0;
      font-size: 1.5rem;
    }
  }
  .contact_form{
    position: relative;
    z-index: 3;
    width: 70%;
    margin: auto;
    margin-top: 50px;
    .form-control{
      height: 50px;
      border-radius: 0;
      background-color: #fff;
      border-color: #fff;
      &::placeholder{
        text-transform: capitalize;
      }
    }
    textarea{
      height: 150px !important;
      resize: none;
    }
  }
  .btn_submit{
    text-align: center;
    .btn{
      background-color: $THIRDCOLOR;
      color: #fff;
      margin-top: 20px;
      border-radius: 0;
      padding: 10px 50px;
    }
  }
  .error_msg{
    color: #fff;
    font-size: .9rem;
  }
}


/* sitefooter */
.sitefooter{
  padding: 20px 0;
  .wrapper{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    h6{
      margin-bottom: 0;
    }
    ul{
      display: flex;
      align-items: center;
      margin-bottom: 0;
      li{
        margin-right: 30px;
        a{
          color: $SECONDCOLOR;
          i{
            margin-right: 5px;
            color: $THIRDCOLOR;
          }
          span{
            font-weight: 600;
          }
        }
      }
    }
  }
}



.check_certificate{
  background-color: #0e142c13;
  // min-height: 100vh;
  // margin: 50px 0 0 0;
  padding-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  .login_wave{  
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    img{
      width: 100%;
    }
  }
  .check_form{
    background-color: #fff;
    padding: 30px 50px;
    width: 50%;
    border-radius: 5px;
    box-shadow: 2px 2px 20px rgba(0,0,0,.3);
    margin: auto;
    text-align: center;
    position: relative;
    z-index: 8;
    margin-bottom: 50px;
    img{
      width: 200px;
      display: inline-block;
      // margin-bottom: 20px;
    }
    h5{
      margin-bottom: 20px;
    }
    .form-group{
      margin-bottom: 0;
      .form-control{
        height: 50px;
        border: 1px solid #eee;
        &::placeholder{
          font-size: .9rem;
        }
        &:focus{
          outline: 0;
          box-shadow: none;
          border-color: #01358fab;
        }
      }
    }
    .btn{
      background-color: $SECONDCOLOR;
      color: #fff;
      padding: 8px 50px;
      margin-top: 20px;
      border: 1px solid $SECONDCOLOR;
      &:hover{
        background-color: #fff;
        color: $SECONDCOLOR;
      }
    }
  }
  .stars{
    position: absolute;
    top: 0;
    i{
      position: absolute;
      color: #fff;
    }
  }
}

.certificate_detials{
  // min-height: 100vh;
  height: auto;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  background-color: #fff;
  padding: 50px 0;
  position: relative;
  overflow: hidden;
  .circle{
    position: absolute;
    top: 50%;
    left: -10%;
    transform: translate(-50%);
    z-index: 1;
  }
  .certificate_card{
    background-color: #fff;
    width: 80%;
    margin: auto;
    padding: 30px;
    border-radius: 10px;
    position: relative;
    z-index: 9;
    box-shadow: 0 5px 15px rgba(0,0,0,.4);
    li{
      display: flex;
      padding: 15px 0;
      border-bottom: 1px solid #eee;
      align-items: center;
      justify-content: space-between;
      .title{
        font-weight: bold;
        font-size: 1.2rem;
      }
      .value{
        font-size: 1.1rem;
      }
    }
  }
  .btn_close{
    text-align: center;
    margin-top: 15px;
    position: relative;
    z-index: 4;
    .btn{
      padding: 10px 50px;
      background-color: $THIRDCOLOR;
      border-radius: 0;
      border: 1px solid $THIRDCOLOR;
      &:hover{
        background-color: #fff;
        color: $THIRDCOLOR;
      }
    }

  }
}

/* not_found */
.not_found{
  text-align: center;
  padding-bottom: 50px;
  img{
    width: 45%;
    margin: auto;
  }
  h5{
    font-size: 2rem;
    text-transform: uppercase;
    color: $SECONDCOLOR;
    margin: 0;
  }
  a{
    background-color: $THIRDCOLOR;
    color: #fff;
    padding: 10px 50px;
    margin-top: 20px;
    display: inline-block;
    border: 1px solid $THIRDCOLOR;
    &:hover{
      background-color: #fff;
      color: $THIRDCOLOR;
    }
  }
}

@media(max-width: 1440px){
  html{
    font-size: 14px;
  }
}

@media(max-width: 991px){
  .check_certificate .check_form{
    width: 100%;
  }
}

@media(max-width: 524px){
  .certificate_card{
    width: 100%;
    li{
      flex-direction: column;
      align-items: flex-start !important;
      .title{
        margin-bottom: 5px;
      }
    }
  }
  .custom_navbar .side_icon{
    display: block;
    padding: 10px 15px;
    // border: 1px solid #fff;
    img{
      width: 35px;
      color: #fff;
      // font-size: 1rem;
      // fill: #fff;
    }
  }
  .custom_navbar ul.list{
    display: none;
  }
  .organization_card .card_logo img{
    width: 100px;
  }
  .sitefooter .wrapper{
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    ul{
      li{
        margin-right: 0;
        margin-top: 15px;
      }
    }
  }
}